import React from "react";
import styles from "./App.module.css";
import Home from "./components/Home";

function App() {

	return (
		<div className={styles.App}>
            <Home />

            <div className={styles.copyrights}>
                ©️ INNER {(new Date()).getFullYear()}
            </div>
		</div>
	);
}

export default App;
