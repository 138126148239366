import styles from "./Home.module.css";
import logo from "../../assets/logo_with_solgan_tw.png";
import appStoreBadge from "../../assets/app-store-badge-english.svg";
import googlePlayBadge from "../../assets/google-play-badge-english.png";
import deliveryGuy from "../../assets/Delivery guy with rack.svg";
import supportGirl from "../../assets/Support girl on desk.svg";
import ParticlesBackground from "../ParticlesBackground/ParticlesBackground";

function Home() {
    
    const showComingSoonAlert = () => {
        alert("ההשקה הרשמית של האפליקציה שלנו תגיע בקרוב 🚀");
    }

	return (
		<div className={styles.Home}>

            <div className={styles.landingSection}>
                <ParticlesBackground
                    key={'particles-background'}
                    // bgColor={config.primary_color}
                    // particlesColor={config.background_color}
                />

                <img src={logo} className={styles.appLogo} alt="logo" />

                <div className={styles.downloadBadgesDiv}>
                    <img 
                        src={appStoreBadge} 
                        className={styles.appStoreBadge} 
                        alt="Download on the App Store" 
                        onClick={showComingSoonAlert}
                    />

                    <img 
                        src={googlePlayBadge} 
                        className={styles.googlePlayBadge} 
                        alt="Get it on Google Play" 
                        onClick={showComingSoonAlert}
                    />
                </div>
            </div>


            <div id="about" className={`${styles.aboutSection} ${styles.landingSectionDivider}`}>
                <div className={styles.aboutSectionContent}>
                    <div className={styles.aboutText}>
                        <h2 className={styles.aboutTitle}>
                            קצת עלינו
                        </h2>

                        INNER הוא שירות משלוחים ייעודי למתחמי משרדים גבוהים עם מגוון מסעדות רחב, שמתמחה בהתמודדות עם תדירות גבוהה של משלוחים לטווחים קצרים.
                        <br /><br />
                        עם פלטפורמה מתקדמת, שירות לקוחות קשוב וגישה חדשנית, אנחנו שואפים להיות הפתרון האידיאלי לארוחה בקליק ולספק ללקוחותינו משלוח מהיר ואיכותי - בחינם. 
                    </div>

                    <img src={deliveryGuy} className={styles.aboutImage} alt="Delivery Guy With Rack" />
                </div>
            </div>

            <div id="support" className={styles.contactSection}>
                <div className={styles.contactSectionContent}>
                    <img src={supportGirl} className={styles.contactImage} alt="Support Desk Girl" />

                    <div className={styles.contactText}>
                        <h2 className={styles.contactTitle}>
                            צור קשר
                        </h2>

                        תוכלו למצוא אותנו באימייל בכתובת&nbsp; <a href="mailto:support@inner-app.com">support@inner-app.com</a>
                        <br />
                        אחרי ההשקה נהיה זמינים עבורכם גם בצ'אט התמיכה באפליקציה 🫶
                    </div>
                </div>
            </div>
		</div>
	);
}

export default Home;
